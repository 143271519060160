/*==============================
Countdown Area 
====================================*/


.countdown-style-1 {
  .timer-grid {
    display: flex;
    margin: 0 -15px;

    @media #{$large-mobile} {
      flex-wrap: wrap;
    }

    @media #{$large-mobile,$md-layout} {
      justify-content: center;
    }

    .ht-count {
      border-radius: 6px;
      margin: 0 15px;
      text-align: center;
      height: 100px;
      width: 100px;
      flex-basis: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media #{$large-mobile} {
        margin: 0 10px 20px;
      }
    }

    .time-count {
      display: inline-block;
      font-size: 36px;
      text-align: center;
      margin-bottom: 10px;
      font-weight: 600;
      line-height: 1;

      @media #{$large-mobile} {
        font-size: 25px;
      }
    }

    .count-inner {
      p {
        margin: 0;
        line-height: 1;
        font-weight: 600;
        font-size: 14px;
        text-transform: capitalize;
      }
    }
  }

  &.bg-solid-grey {
    .timer-grid {
      span {
        &.ht-count {
          background: #f3f3f3;
          color: #222;
        }
      }
    }
  }

  &.bg-solid-theme {
    .timer-grid {
      span {
        &.ht-count {
          background: var(--clr-theme);
          color: #ffffff;
        }
      }
    }
  }
}

.countdown-style-2 {
  &.font-lg-size {
    .timer-grid {
      display: flex;
      text-align: center;

      .ht-count {
        &:not(:last-child) {
          margin-right: 30px;

          @media #{$large-mobile} {
            margin-right: 15px;
          }
        }

        p {
          text-transform: capitalize;
        }
      }

      .second {
        span {
          &.time-count {
            color: var(--clr-theme);
          }
        }
      }

      span {
        &.time-count {
          color: #222222;
          font-size: 67px;
          padding: 0 4px;
          width: auto;
          display: block;
          line-height: 1;
          font-weight: 400;
          margin-bottom: 10px;

          @media #{$sm-layout} {
            font-size: 40px;
            margin-bottom: 4px;
          }

          @media #{$large-mobile} {
            font-size: 30px;
          }
        }
      }
    }
  }
}


.time-circles {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  margin: -15px;

  .ht-count {
    border: 4px solid #0038E3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 160px;
    height: 160px;
    width: 160px;
    margin: 15px;


    @media #{$md-layout} {
      flex-basis: 120px;
      height: 120px;
      width: 120px;
    }


    @media #{$large-mobile} {
      flex-basis: 100px;
      height: 100px;
      width: 100px;
    }

    .time-count {
      font-size: 30px;
      font-weight: 500;
    }

    p {
      color: #999999;
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 0;
    }
  }
}
