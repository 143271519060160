/* -------------------
Counterup Area 
----------------------*/

/* ---------------------------
    Default Counter Area 
-----------------------------*/

.counter-fullwidth-grid {
  width: 100%;
  max-width: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media #{$md-layout} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media #{$sm-layout} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media #{$large-mobile} {
    grid-template-columns: repeat(1, 1fr);
  }

}

.brook-counter {
  .icon {
    line-height: 1;
    margin-bottom: 22px;

    i {
      font-size: 80px;
      color: var(--clr-theme);
    }
  }

  .content {
    span {
      margin-bottom: 29px;
      font-size: 48px;
      font-weight: 700;
      color: var(--clr-heading);
      display: block;
    }

    h6 {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      color: var(--clr-body);
    }
  }

  &.counter-with-border {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #ddd;
    padding: 40px;
    justify-content: center;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);

    @media #{$lg-layout} {
      padding: 63px 30px;
    }
    @media #{$md-layout} {
      padding: 63px 30px;
    }


    &::before {
      content: "";
      padding-top: 100%;
    }

    &:hover {
      border: 30px solid var(--clr-theme);

      @media #{$lg-layout} {
        border: 5px solid #0038E3;
      }

      @media #{$md-layout} {
        border: 5px solid #0038E3;
      }

      @media #{$sm-layout} {
        border: 5px solid #0038E3;
      }
    }
  }

  &.onepage-counter {
    .content {
      span {
        margin-bottom: 21px;
        font-size: 80px;
        font-weight: 600;
        line-height: .9;

        @media #{$sm-layout} {
          font-size: 60px;
        }
      }

      h6 {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        color: var(--clr-body);
      }
    }
  }


  &.product-count {
    .icon {
      i {
        font-size: 80px;
      }
    }

    .content {
      span {
        margin-bottom: 30px;
      }
    }
  }

  &-1 {
    margin-top: 40px;
  }
}


.counterup-bg-shape {
  background-image: url(../../img/icons/one-page-shape.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom 200px;
}


/* -------------------
Counterup Area 
----------------------*/


.number-speaks {
  .content {
    h3 {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
      line-height: 1.43;
      margin-bottom: 0;
      word-wrap: break-word;
      font-family: var(--ff-body);
    }

    h2 {
      font-size: 48px;
      line-height: 1.25;
      font-weight: 700;
      margin-bottom: 0;
      word-wrap: break-word;
      max-width: 100%;
      color: #222222;
      margin-top: 38px;
    }

    a {
      &.bk-btn {
        margin-top: 40px;
      }
    }

  }
}


.number-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 70px;

  span {
    font-size: 70px;
    font-weight: 700;
    line-height: .9;
    font-family: var(--ff-heading);

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .content {
    padding-left: 20px;
    text-align: left;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;

    h6 {
      font-size: 18px;
      line-height: 1.39;
      margin-bottom: 0;
      color: #222222;
      font-family: var(--ff-body);
      font-weight: 600;
    }

    p {
      margin-top: 20px;
      font-family: var(--ff-heading);
      line-height: 1.32;
      font-size: 16px;
      margin-bottom: 0;
      color: #333;
    }

  }
}


/* ==========================
    Conter Style 2 
===========================*/

.counter-grid {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 50px;
  width: 100%;
  max-width: 100%;
  display: -ms-grid;
  display: grid;

  @media #{$sm-layout} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media #{$small-mobile} {
    grid-template-columns: repeat(1, 1fr);
  }

}

.counter-style--2 {
  text-align: center;

  .icon {
    font-size: 80px;
    line-height: 1;
    display: block;
    margin-bottom: 10px;

    i {
      font-size: 80px;
      color: #FE378C;
    }
  }

  .count {
    font-size: 48px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 3px;
    line-height: 1.6;
    color: #fff;
  }

  .content {
    h6 {
      font-size: 15px;
      font-weight: 500;
      font-style: italic;
      line-height: 1.6;
      color: #fff;
      font-family: 'Montserrat', sans-serif;
      margin: 0;
    }
  }
}
















