/* ========================
Pricing Plans
==========================*/


.ht-pricing-list-view {
  box-shadow: 0 0 40px rgba(0, 0, 0, .06);

  table {
    border: 0;
  }

  thead {
    tr {
      th {
        &.pricing-header {
          padding: 0;
          width: 266px;
          border: 0;
          border-left: 1px solid rgba(51, 51, 51, .1);
          border-right: 1px solid rgba(51, 51, 51, .1);
          text-align: center;

          .heading-h4 {
            font-size: 24px;
            color: var(--clr-heading);
            font-family: var(--ff-body);
            font-weight: 600;
            letter-spacing: 0em;
            line-height: 1.23;
            margin: 0;
          }

          .heading-h6 {
            font-size: 14px;
            color: var(--clr-heading);
            font-family: var(--ff-body);
            font-weight: 600;
            letter-spacing: 0em;
            line-height: 1.23;
            margin: 0;
          }

          &:first-child {
            text-align: left;
            width: 370px;
            padding: 52px 30px 32px 46px;
            border-left: 0;
          }
        }

        &.pricing-header {
          padding: 52px 30px 32px;
          position: relative;

          .feature-park {
            background: blue;
            padding: 6px 21px;
            position: absolute;
            top: 15px;
            left: 50%;
            -webkit-transform: translate(-50%);
            -ms-transform: translate(-50%);
            transform: translate(-50%);
            color: #fff;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 0em;
            line-height: 1.23;
            white-space: nowrap;
          }

          .heading-h6 {
            text-transform: uppercase;
            margin-bottom: 34px;
            display: block;
          }

          .price-wrap {
            .price-wrap-inner {
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: start;
              -webkit-align-items: flex-start;
              -ms-flex-align: start;
              align-items: flex-start;
              -webkit-box-pack: center;
              -webkit-justify-content: center;
              -ms-flex-pack: center;
              justify-content: center;

              span {
                color: var(--clr-heading);

                &.currency {
                  font-size: 18px;
                  margin-bottom: 0;
                }

                &.price {
                  font-size: 36px !important;
                  line-height: .9;
                  margin: 0 4px;
                  font-weight: 600 !important;
                }

                &.period {
                  font-size: 14px;
                  margin-bottom: 0;
                  color: #999;
                  -webkit-align-self: flex-end;
                  -ms-flex-item-align: end;
                  align-self: flex-end;
                  line-height: 2;
                }
              }
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border: 0;
        border-left: 1px solid rgba(51, 51, 51, .1);
        border-right: 1px solid rgba(51, 51, 51, .1);
        padding: 0 30px;

        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }

        &.price-list {
          ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
              border-top: 1px solid rgba(51, 51, 51, .1);
              padding: 9px 0;

              span {

                &.item-checked {
                  color: #7ed321;
                  font-size: 16px;
                }
              }

              &.ft--foot {
                padding: 30px 10px 40px;
              }
            }
          }
        }
      }
    }
  }
}




















