/**
 * Typography
 */

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

html {
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

body {
  overflow: hidden;
  font-size: var(--fs-body);
  line-height: var(--lh-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--ff-body);
  color: var(--clr-body);
  font-weight: 400;
}


a {
  transition: all 0.4s ease-in-out 0s;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
dl,
dd,
menu,
ol,
ul,
table,
caption,
hr {
  margin: 0;
  margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--ff-heading);
  line-height: 1.3;
  color: var(--clr-heading);
}

h1,
.h1 {
  font-size: var(--fs-h1);
}

h2,
.h2 {
  font-size: var(--fs-h2);
}

h3,
.h3 {
  font-size: var(--fs-h3);
}

h4,
.h4 {
  font-size: var(--fs-h4);
}

h5,
.h5 {
  font-size: var(--fs-h5);
}

h6,
.h6 {
  font-size: var(--fs-h6);
}

@media #{$md-layout} {
  h1,
  .h1 {
    font-size: var(--fs-h1) - 4;
  }

  h2,
  .h2 {
    font-size: var(--fs-h2) - 2;
  }
  h3,
  .h3 {
    font-size: var(--fs-h3) - 2;
  }
}

@media #{$sm-layout} {
  h1,
  .h1 {
    font-size: var(--fs-h1) - 6;
  }

  h2,
  .h2 {
    font-size: var(--fs-h2) - 4;
  }
  h3,
  .h3 {
    font-size: var(--fs-h3) - 2;
  }
}

@media #{$large-mobile} {
  h1,
  .h1 {
    font-size: var(--fs-h1) - 8;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: 700;
}

h4,
.h4,
h5,
.h5 {
  font-weight: 600;
}

h6,
.h6 {
  font-weight: 500;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a:visited {
  color: inherit;
}

input,
button,
select,
textarea {
  background: transparent;
  border: 1px solid var(--clr-border);
  transition: all 0.4s ease-out 0s;
  color: var(--clr-body);

  &:focus,
  &:active {
    outline: none;
    border-color: var(--clr-theme);
  }
}

input,
select,
textarea {
  width: 100%;
  font-size: 14px;
}

input,
select {
  height: 40px;
  padding: 0 15px;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.brook-call-to-action {
  &.startup {
    .input-box {
      margin-top: 0;
    }
  }
}

.brook-strategy-area {
  &.startup {
    .bk-list--2 {
      .heading {
        font-size: 14px;
        color: var(--clr-white) !important;
      }
    }
  }
}
.wavify-wrapper{
  z-index: -1;
}
.text-inherit {
  color: inherit;
}
.brook-call-to-action, .brook-breadcrumb-area, .breadcrumb-area {
  isolation: isolate;
  .overlay {
    z-index: -1;
  }
}
.h-100vh{
  height: 100vh;
}