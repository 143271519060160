/*========================
 * Header
=========================================*/

.page_nav {
  ul {
    &.mainmenu {
      display: flex;
      padding: 0;
      margin: 0;
      align-items: center;
    }
  }
}


/* ============================
    Header Default Area 
================================*/


/* Maninmenu Area */

.mainmenu-wrapper {
  .page_nav {
    ul {
      &.mainmenu {
        justify-content: center;
        padding: 0;
        margin: 0;

        li {

          &.label-1 {
            display: inline-block;
            padding: 41px 9px 41px 0;

            > a {
              position: relative;
              overflow: hidden;
              z-index: 1;
              font-weight: 600;
              line-height: 1.18;
              font-size: 16px;
              font-family: var(--ff-body);
              color: var(--clr-theme);
              padding: 41px 15px;
              -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
              transition: all .3s cubic-bezier(.645, .045, .355, 1);

              span {
                position: relative;
                overflow: hidden;

                &::after {
                  content: "";
                  width: 0;
                  height: 1px;
                  bottom: 0;
                  position: absolute;
                  left: auto;
                  right: 0;
                  z-index: -1;
                  -webkit-transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
                  transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
                  background: currentColor;
                }
              }
            }

            &:hover {
              > a {
                span {
                  &::after {
                    width: 100%;
                    left: 0;
                    right: auto;
                  }
                }
              }
            }

            &.with--drop {
              > a {
                position: relative;
                margin-left: 13px;
                padding-right: 21px;

                @media #{$lg-layout} {
                  margin-left: 3px;
                }

                &::before {
                  content: "\f107";
                  font-size: 14px;
                  position: absolute;
                  top: 50%;
                  right: 0;
                  -webkit-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                  transform: translateY(-50%);
                  font-family: 'FontAwesome';
                  font-weight: 900;
                }
              }
            }
          }
        }
      }
    }
  }
}

.lg-not-transparent {
  // Responsive
  @media #{$lg-layout}{
    position: fixed !important;
    background-color: var(--clr-white) !important;
    padding-top: 0px !important;
  }
  @media #{$md-layout}{
    position: fixed !important;
    background-color: var(--clr-white) !important;
    padding-top: 0px !important;
  }
  @media #{$sm-layout}{
    position: fixed !important;
    background-color: var(--clr-white) !important;
    padding-top: 10px !important;
  }
}

header.br_header.header-default.vertical-slide-portfolio {
  background: #fff;
}


.header-default {
  position: relative;
  z-index: 9;

  &.header-fixed-width {
    &.header-fixed-150 {
      .container-fluid {
        padding-left: 150px;
        padding-right: 150px;

        @media #{$lg-layout} {
          padding-left: 50px;
          padding-right: 50px;
        }

        @media #{$laptop-device} {
          padding-left: 50px;
          padding-right: 50px;
        }

        @media #{$md-layout} {
          padding-left: 50px;
          padding-right: 50px;
        }

        @media #{$sm-layout} {
          padding-left: 50px;
          padding-right: 50px;
        }

        @media #{$small-mobile} {
          padding-left: 20px;
          padding-right: 20px;
        }

      }
    }

    &.haeder-fixed-130 {
      .container-fluid {
        padding-left: 130px;
        padding-right: 130px;

        @media #{$lg-layout} {
          padding-left: 50px;
          padding-right: 50px;
        }

        @media #{$md-layout} {
          padding-left: 50px;
          padding-right: 50px;
        }

        @media #{$sm-layout} {
          padding-left: 50px;
          padding-right: 50px;
        }

        @media #{$small-mobile} {
          padding-left: 20px;
          padding-right: 20px;
        }

      }
    }

    .container-fluid {
      padding-left: 100px;
      padding-right: 100px;

      @media #{$lg-layout} {
        padding-left: 50px;
        padding-right: 50px;
      }

      @media #{$md-layout} {
        padding-left: 50px;
        padding-right: 50px;
      }

      @media #{$sm-layout} {
        padding-left: 50px;
        padding-right: 50px;
      }

      @media #{$small-mobile} {
        padding-left: 20px;
        padding-right: 20px;
      }

    }
  }

  // Header Transparent
  &.header-transparent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;

    &.position-from--top {
      padding-top: 24px;

      &.sticky {
        padding-top: 0;
      }
    }
  }

  // Header Wrapper
  .header__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    @media #{$md-layout} {
      position: relative;
      z-index: 9;
    }

    .header-left {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-flex-basis: 20%;
      -ms-flex-preferred-size: 20%;
      flex-basis: 20%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;

      @media #{$md-layout, $sm-layout, $large-mobile} {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
      }

      &.flex-80{
        flex-basis: 80%;
      }

      .logo {
        a {
          img {
            padding-top: 15px;
            padding-bottom: 15px;
            width: 170px;
            height: auto;
            max-width: 100%;

            @media #{$lg-layout} {
              width: 140px !important;
            }

            @media #{$sm-layout} {
              width: 110px !important;
            }
          }
        }
      }

    }

    // Mainmenu
    .header-flex-right {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;

      &.flex-80 {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
      }
    }

    .mainmenu-wrapper {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;

      &.have-not-flex {
        flex-grow: inherit;

        .page_nav {
          ul {
            &.mainmenu {
              justify-content: flex-end;
            }
          }
        }
      }
    }

    // Header Right
    .header-right {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-flex-basis: 80%;
      -ms-flex-preferred-size: 80%;
      flex-basis: 80%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin: 0 -12px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;

      // Not Flex Header
      &.have-not-flex {
        flex-basis: inherit;

      }

      &.flex-20 {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
      }

      @media #{$md-layout, $sm-layout, $large-mobile} {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
      }

    }
  }

  .header-right {
    margin: 0 -12px;

    > div {
      margin: 0 12px;
    }

    // Start Minicart
    .mini-cart-button {
      position: relative;
      display: block;
      width: 30px;
      height: 30px;
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      cursor: pointer;

      &::after {
        position: absolute;
        top: -4px;
        right: -13px;
        padding: 0 4px;
        min-width: 15px;
        height: 15px;
        border-radius: 100%;
        content: attr(data-count);
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        background-color: rgba(0, 0, 0, 0);
        color: #fff;
      }

      button {
        border: 0 none;
      }
    }
  }

  // Header Color Light
  &.light-logo--version {
    .mainmenu-wrapper {
      .page_nav {
        ul {
          &.mainmenu {
            li {
              a {
                color: #ffffff;

                &::after {
                  background: #ffffff;
                }
              }

              &.label-1 {
              }

              &.with--drop {
                &::before {
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }

    .header-right {
      .mini-cart-button {
        color: #fff;

        &::after {
          color: #fff;
        }

        button {
          color: #fff;
        }
      }


      .popup-search-wrap {
        button {
          &.btn-search-click {
            border: none;
            color: #fff;
          }
        }
      }

    }
  }


  &.black-logo--version, &.dark-logo--version {
    .mainmenu-wrapper {
      .page_nav {
        ul {
          &.mainmenu {
            li {
              a {
                color: #222222;

                &::after {
                  background: #222222;
                }
              }

              &.label-1 {
              }

              &.with--drop {
                &::before {
                  color: #222222;
                }
              }
            }
          }
        }
      }
    }

    .header-right {
      .mini-cart-button {
        color: #222222;

        &::after {
          color: #222222;
        }

        button {
          color: #222222;
        }
      }

      .popup-search-wrap {
        button {
          &.btn-search-click {
            border: none;
            color: #222222;
          }
        }
      }
    }
  }
}

/* ==========================
    Header left Align 
===========================*/

.header-left--align.header-default .header__wrapper .header-left .logo a img {
  width: auto !important;
}

.header-left--align.header-default .header__wrapper .header-left {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.header-left--align.header-default .header__wrapper .header-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.header-left--align.header-default .header__wrapper .mainmenu-wrapper.have-not-flex .page_nav ul.mainmenu {
  justify-content: flex-start;
}

.header-left--align .mainmenu-wrapper .page_nav ul.mainmenu li.label-1.with--drop > a::before {
  display: none;
}

.header-left--align .mainmenu-wrapper .page_nav ul.mainmenu li.label-1.with--drop > a {
  margin-left: 8px;
  padding-right: 8px;
}


/* ============================
    Sticky Header Css 
================================*/

.black-logo--version, .dark-logo--version {
  &.is-sticky {
    &.sticky {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
}

.is-sticky {
  &.sticky {
    background-color: rgba(0, 0, 0, 0.9);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .1);
    -webkit-animation: headerSlideDown .95s ease forwards;
    animation: headerSlideDown .95s ease forwards;
  }
}

.is-sticky.sticky .mainmenu-wrapper .page_nav ul.mainmenu li.label-1 {
  padding-top: 31px;
  padding-bottom: 31px;
}

.is-sticky.sticky .mainmenu-wrapper .page_nav ul.mainmenu li.label-1 > a {
  padding-top: 31px;
  padding-bottom: 31px;
}

.header-default.sticky .header__wrapper .header-left .logo a img {
  width: 150px;
}

.header-default.header-architecture.sticky .header__wrapper .header-left .logo a img {
  width: auto;
}

.header-default.header-portfolio-slide.sticky .header__wrapper {
  padding: 16px 0;
}


/* =================================
    Header One Page 
==================================*/

.one-page-header {
  .header-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    height: 100%;

    // Header Left
    .header-left-wrapper {
      background-color: #222;
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: scroll;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -ms-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      padding-left: 76px;
      padding-right: 43px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      width: 50%;
      @media #{$large-mobile} {
        width: 100%;
      }

      .branding {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;

        a {
          img {
            padding-top: 14px;
            padding-bottom: 14px;
            width: 52px;
          }
        }
      }

      .header-left-inner {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        height: 30px;
        cursor: pointer;


      }

    }


    // Header Right
    .header-right-wrapper {
      background: #19d2a8;
      background-color: #19d2a8;
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: scroll;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -ms-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      padding-left: 30px;
      padding-right: 49px;
      width: 50%;

      .header-text {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin: 0 -12px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        height: 100%;
      }
    }
  }
}


/*==========================
Hamburger Style Two
============================*/

.menu-hamburger-2 {
  padding: 0 12px;
  color: #fff;

  div {
    position: relative;
    width: 24px;
    height: 17px;
    cursor: pointer;

    i {
      height: 3px;
      -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
      width: 100%;
      background: currentColor;

      &::before,
      &::after {
        position: absolute;
        left: 50%;
        width: 100%;
        height: 100%;
        background: inherit;
        content: "";
        -webkit-transition: width .3s cubic-bezier(.645, .045, .355, 1);
        transition: width .3s cubic-bezier(.645, .045, .355, 1);
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
        left: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
      }

      &::before {
        width: 16px;
        top: -7px;
      }

      &::after {
        width: 18px;
        top: 7px;
      }
    }

    &:hover {
      i {
        width: 16px;

        &::before,
        &::after {
          width: 70%;
        }

        &::before {
          width: 24px;
        }

        &::after {
          width: 22px;
        }
      }
    }
  }

  &.dak-version {
    color: #222222;
  }
}

/*=================================
    Header Fixed 
=====================================*/
header.br_header.header-default.header-fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}