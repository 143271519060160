/* ======================
Freelancer Area 
==========================*/


.freelancer-wrapper{
    padding-top: 59px;
    padding-right: 135px;
    padding-left: 135px;

    @media #{$laptop-device} {
        padding-right: 70px;
        padding-left: 70px;
    }

    @media #{$lg-layout} {
        padding-right: 40px;
        padding-left: 40px;
    }

    @media #{$md-layout} {
        padding-right: 40px;
        padding-left: 40px;
    }

    @media #{$sm-layout} {
        padding-right: 30px;
        padding-left: 30px;
    }

    &.bg_image--27{
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: top 150px center;
        background-size: auto;
    }

    .freelancer-left{
        width: 480px;
        max-width: 100%;

        @media #{$md-layout} {
            width: 100%;
        }
    }

    .freelancer-right{
        width: 595px;
        max-width: 100%;
        float: right;

        @media #{$md-layout} {
            width: 100%;
            float: left;
        }

    }
}
























