/*---------------------------------------
    13. Checkout
-----------------------------------------*/

/*-- Checkout Title --*/

.checkout-title {
    font-size: 20px;
    line-height: 23px;
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 30px;
}

/*-- Checkout Form --*/

.checkout-form {
    & label {
        display: block;
        font-size: 14px;
        margin-bottom: 12px;
        font-weight: 600;
        text-transform: capitalize;
        color: var(--clr-heading);
    }
    & .nice-select {
        width: 100%;
        background-color: transparent;
        border: 1px solid #999999;
        border-radius: 50px;
        line-height: 23px;
        padding: 10px 20px;
        font-size: 14px;
        height: 45px;
        color: var(--clr-body);
        margin-bottom: 15px;
        &::after {
            width: 6px;
            height: 6px;
            border-width: 1px;
            right: 20px;
            border-color: var(--clr-body);
        }
        & .current {
            color: var(--clr-body);
            display: block;
            line-height: 23px;
        }
        & .list {
            width: 100%;
        }
    }
    & input {
        width: 100%;
        background-color: transparent;
        border: 1px solid #999999;
        border-radius: 50px;
        line-height: 23px;
        padding: 10px 20px;
        font-size: 14px;
        color: var(--clr-body);
        margin-bottom: 15px;
        &[type="checkbox"] {
            width: auto;
        }
    }
    & .check-box {
        float: left;
        margin-right: 70px;
        &:last-child {
            margin-right: 0;
        }
        & input[type="checkbox"] {
            display: none;
            &+label {
                position: relative;
                padding-left: 30px;
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: var(--clr-heading);
                margin: 0;
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 20px;
                    height: 20px;
                    display: block;
                    border: 2px solid #999999;
                    content: "";
                    transition: all 0.3s ease 0s;
                }
                &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: block;
                    content: "\f00c";
                    font-family: 'FontAwesome';
                    font-weight: 900;
                    font-size: 12px;
                    line-height: 20px;
                    opacity: 0;
                    color: var(--clr-heading);
                    width: 20px;
                    text-align: center;
                    transition: all 0.3s ease 0s;
                }
                @media #{$large-mobile} {
                    margin-top: 20px;
                }
            }
            &:checked+label {
                &::before {
                    border: 2px solid var(--clr-heading);
                }
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}

/*-- Shipping Form --*/

#shipping-form {
    display: none;
}

/*-- Checkout Cart Total --*/

.checkout-cart-total {
    background-color: #f6f6f6;
    padding: 45px; // Responsive
    @media #{$small-mobile} {
        padding: 30px 19px;
    }
    & h4 {
        flex-basis: 18px;
        line-height: 23px;
        font-weight: 700;
        &:first-child {
            margin-top: 0;
            margin-bottom: 25px;
        }
        &:last-child {
            margin-top: 15px;
            margin-bottom: 0;
        }
        & span {
            float: right;
            display: block;
        }
    }
    & ul {
        border-bottom: 1px solid #999999;
        padding: 0;
        margin: 0;
        & li {
            color: var(--clr-body);
            font-size: 14px;
            line-height: 23px;
            font-weight: 600;
            display: block;
            margin-bottom: 16px;
            & span {
                color: var(--clr-heading);
                float: right;
            }
        }
    }
    & p {
        font-size: 14px;
        line-height: 30px;
        font-weight: 600;
        color: #505050;
        padding: 10px 0;
        border-bottom: 1px solid #999999;
        margin: 0;
        & span {
            float: right;
        }
    }
}

/*-- Checkout Payment Method --*/

.checkout-payment-method {
    background-color: #f6f6f6;
    padding: 45px; // Responsive
    @media #{$small-mobile} {
        padding: 30px;
    }
}

/*-- Single Payment Method --*/

.single-method {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    & input[type="radio"] {
        display: none;
        &+label {
            position: relative;
            padding-left: 30px;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: var(--clr-heading);
            margin: 0;
            &::before {
                position: absolute;
                left: 0;
                top: 0;
                width: 20px;
                height: 20px;
                display: block;
                border: 2px solid #999999;
                content: "";
                transition: all 0.3s ease 0s;
            }
            &::after {
                position: absolute;
                left: 5px;
                top: 5px;
                display: block;
                content: "";
                opacity: 0;
                background-color: var(--clr-heading);
                width: 10px;
                text-align: center;
                height: 10px;
                transition: all 0.3s ease 0s;
            }
        }
        &:checked+label {
            &::before {
                border: 2px solid var(--clr-heading);
            }
            &::after {
                opacity: 1;
            }
        }
    }
    & input[type="checkbox"] {
        display: none;
        &+label {
            position: relative;
            padding-left: 30px;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: var(--clr-body);
            margin: 0;
            &::before {
                position: absolute;
                left: 0;
                top: 0;
                width: 16px;
                height: 16px;
                display: block;
                border: 2px solid #999999;
                content: "";
                transition: all 0.3s ease 0s;
            }
            &::after {
                position: absolute;
                left: 4px;
                top: 4px;
                display: block;
                content: "";
                opacity: 0;
                background-color: var(--clr-heading);
                width: 8px;
                text-align: center;
                height: 8px;
                transition: all 0.3s ease 0s;
            }
        }
        &:checked+label {
            &::before {
                border: 2px solid var(--clr-heading);
            }
            &::after {
                opacity: 1;
            }
        }
    }
    & p {
        display: none;
        margin-top: 8px;
        font-size: 14px;
        color: var(--clr-heading);
        line-height: 23px;
    }
}

/*-- Place Order --*/

.place-order {
    background-color: var(--clr-heading);
    border: medium none;
    border-radius: 50px;
    color: #fff;
    float: left;
    font-size: 14px;
    font-weight: 700;
    height: 36px;
    line-height: 24px;
    margin-top: 40px;
    padding: 6px 20px;
    text-transform: uppercase;
    width: 158px;
}