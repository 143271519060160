/*-----------------------
 * Comments & Forms
-------------------------*/

.login-form-wrapper {
    padding: 30px;
    border: 1px solid #d5d5d5
}

.sn-form-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -20px;
    .single-input {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 20px;
        &.single-input-half {
            flex: 0 0 calc(50% - 15px);
            max-width: calc(50% - 15px);
        }
        label {
            font-weight: 400;
            margin-bottom: 8px;
            display: block;
            color: var(--clr-heading);
        }
        input,
        select,
        textarea,
        .nice-select {
            border-radius: 3px;
            border: 1px solid #e5e5e5;
        }
        input+input {
            margin-top: 20px;
        }
        .checkbox-input {
            display: inline-block;
            input {
                display: inline-block;
                height: 14px;
                width: 14px;
                vertical-align: middle;
            }
            label {
                display: inline-block;
            }
        }
        button {
            display: inline-block;
            border: 1px solid var(--clr-heading);
            font-weight: 700;
            font-size: 14px;
            padding: 10px 35px 9px 35px;
            text-transform: uppercase;
            text-align: center;
            transition: all 0.4s ease-in-out 0s;
            background: var(--clr-heading);
            color: #ffffff;
            
            @media #{$large-mobile} {
                margin-bottom: 20px;
            }
            &:hover {
                opacity: 0.7;
            }
        }
    }
    a {
        color: var(--clr-body);
        font-size: 13px;
        &:hover {
            color: var(--clr-theme);
        }
    }
}

@media #{$sm-layout} {
    .sn-form {
        .single-input {
            &.single-input-half {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}