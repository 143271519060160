/* =======================
Wavify Area 
==============================*/

.wavify-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  .wavify-item {
    height: 100%;

    svg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}




