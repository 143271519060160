/*============================
    Blog Metro Grid Style 3 
=============================*/

.bk-blog-masonry {
    margin: 0 -15px;
    
    .blog-33-33 {
        width: 33.33%;
        padding: 0 15px;
        margin-bottom: 30px;

        @media #{$md-layout} {
            width: 50%;
        }

        @media #{$sm-layout} {
            width: 50%;
        }

        @media #{$large-mobile} {
            width: 100%;
        }
    }
    .resizer {
        width: 33.33%;

        @media #{$md-layout} {
            width: 50%;
        }

        @media #{$sm-layout} {
            width: 50%;
        }

        @media #{$large-mobile} {
            width: 100%;
        }
    }
}



.bk-blog-masonry2{
    .blog-25 {
        width: 25%;
    }
    .grid-width-2{
        width: 50%;
    }
    .resizer {
        width: 25%;
    }
    
    @media #{$lg-layout} {
        .blog-25 {
            width: 50%;
        }
        .grid-width-2{
            width: 50%;
        }
        .resizer {
            width: 50%;
        }
    }
    @media #{$md-layout} {
        .blog-25 {
            width: 50%;
        }
        .grid-width-2{
            width: 50%;
        }
        .resizer {
            width: 50%;
        }
    }
    @media #{$sm-layout} {
        .blog-25 {
            width: 100%;
        }
        .grid-width-2{
            width: 100%;
        }
        .resizer {
            width: 100%;
        }
    }

}



.bk-blog-masonry3{
    .blog-33-33 {
        width: 33.33%;
    }
    .grid-width-2{
        width: 66.66%;
    }
    .resizer {
        width: 33.33%;
        
    }
}

















