/* ==========================
    Masonry Button
=============================*/

.masonry-button {
  button {
    padding: 0;
    border: 0 none;
    position: relative;

    span {
      position: relative;
      display: inline-block;
      padding: 5px 15px;

      &.filter-text {
        font-weight: 600;
        line-height: 1.58;
        font-size: 14px;
        color: var(--clr-body);
        -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
        transition: all .3s cubic-bezier(.645, .045, .355, 1);
      }

      &.filter-counter {
        position: absolute;
        top: 0;
        left: 50%;
        visibility: hidden;
        margin: 0 auto;
        min-width: 34px;
        height: 24px;
        line-height: 13px;
        border-radius: 3px;
        font-size: 12px;
        background-color: var(--clr-theme);
        color: #fff;
        text-align: center;
        opacity: 0;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
        transition: all .3s cubic-bezier(.645, .045, .355, 1);
        font-weight: 600;
        font-family: var(--ff-body);

        &::before {
          position: absolute;
          right: 0;
          bottom: -4px;
          left: 0;
          display: block;
          margin: 0 auto;
          width: 0;
          height: 0;
          border-top: 4px solid var(--clr-theme);
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          content: "";
          border-top-color: var(--clr-theme);
        }
      }
    }

    &:hover {
      span {
        &.filter-counter {
          visibility: visible;
          opacity: 1;
          -webkit-transform: translate(-50%, -100%);
          -ms-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
        }

        &.filter-text {
          color: var(--clr-theme);
        }
      }
    }

    &.is-checked {
      span {
        &.filter-text {
          color: var(--clr-theme);
        }
      }
    }
  }

}


.toggle-btn {
  .loadMore {
    font-size: 14px;
    font-weight: 600;
    color: var(--clr-heading);
    border: 0 none;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);

    &:hover {
      color: var(--clr-theme);
    }

    &.disabled{
      opacity: 0.4;
      pointer-events: none;
    }

    i {
      margin-left: 5px;
    }
  }
}


/* ==============================
    Portfolio Button Style 2 
==================================*/

.masonry-button--2 {
  button {
    position: relative;
    display: inline-block;
    padding: 5px 30px 5px 10px;
    color: #888;
    border: 0 none;

    @media #{$sm-layout} {
      margin-bottom: 18px;
    }

    span {
      -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
      transition: all .3s cubic-bezier(.645, .045, .355, 1);

      &.filter-text {
        letter-spacing: .2px;
        font-weight: 600;
        color: #333;
        line-height: 1.58;
        font-size: 14px;
      }

      &.filter-counter {
        position: absolute;
        top: -7px;
        right: 10px;
        font-size: 12px;
        color: #333;
        text-align: right;
      }
    }

    &.is-checked {
      span {
        &.filter-text {
          color: #001029;
        }

        &.filter-counter {
          color: #001029;
        }
      }
    }
  }
}























