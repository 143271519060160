/* ==========================
    Sidebar Mobile Menu 
==============================*/

.popup-mobile-menu-wrapper {
  .popup-mobile-menu {
    visibility: visible;
    opacity: 1;

    .inner {
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
    }
  }
}


.popup-mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  z-index: 99999;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .9);
    cursor: url(../../img/icons/light-close.png) 16 16, pointer;
  }

  .inner {
    background: #000000;
    position: relative;
    width: 400px;
    max-width: 80%;
    height: 100%;
    text-align: left;
    -webkit-transition: all .3s;
    transition: all .3s;
    overflow-y: auto;
    padding-top: 85px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    cursor: default;

    & .mobileheader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 0 15px 0 30px;
      height: 85px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      background: #fff;

      .logo {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;

        a {
          img {
            width: 115px;
            height: auto;
            max-width: 100%;
          }
        }
      }

      button {
        &.mobile-close {
          border: none;
          position: relative;
          cursor: pointer;
          height: 40px;
          width: 40px;
          -webkit-flex-shrink: 0;
          -ms-flex-negative: 0;
          flex-shrink: 0;

          &::before,
          &::after {
            position: absolute;
            top: 19px;
            left: 8px;
            content: "";
            width: 24px;
            height: 3px;
            background: #222;
            -webkit-transform-origin: 50% 50%;
            -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
            transition: all .3s cubic-bezier(.645, .045, .355, 1);
          }

          &::before {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }

          &::after {
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          &:hover {
            &::before,
            &::after {
              -webkit-transform: none;
              -ms-transform: none;
              transform: none;
            }
          }
        }
      }
    }


  }
}


/* =======================
    Header menu 
==========================*/


.object-custom-menu {
  padding: 0;
  list-style: none;
  margin: 20px 40px 30px;

  li {
    position: relative;

    a {
      padding-top: 19px;
      padding-bottom: 19px;
      display: block;
      position: relative;

      span {
        font-size: 14px;
        color: #999999;
      }
    }

    &.has-mega-menu {
      & > a {
        &::after {
          position: absolute;
          top: 50%;
          right: 0;
          width: 40px;
          height: 40px;
          background: hsla(0, 0%, 100%, .1);
          color: #fff;
          text-align: center;
          line-height: 40px;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          content: "\f107";
          font-family: 'FontAwesome';
          font-weight: 900;
          -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
          transition: all .3s cubic-bezier(.645, .045, .355, 1);
        }

        &.active {
          &::after {
            content: "\f106";
          }
        }
      }

      .object-submenu {
        padding: 0;
        margin: 30px 0;
        list-style: none;

        li {
          &.title {
            font-weight: 700;
            line-height: 1.5;
            text-transform: uppercase;
            font-size: 14px;
            color: #fff;
            margin: 0 0 18px;
          }

          a {
            padding-top: 10px;
            padding-bottom: 10px;
            display: block;


            span {
              font-size: 14px;
              color: #999999;
            }
          }
        }
      }
    }
  }

  & > li {
    &:not(:first-child) {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
    }
  }
}












